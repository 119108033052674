
// For usage, see: https://www.npmjs.com/package/uqr
import {
    renderSVG,
}  from '../../lib/uqr/dist/index';

export const showSvg = (url?: string, svgContainerElement?: HTMLElement, svgDownloadLinkElement?: HTMLLinkElement) => {
    if (url) {
        const svg = renderSVG(url);
        if (svgContainerElement) {
            svgContainerElement.innerHTML = svg;
        }
        if (svgDownloadLinkElement) {
            svgDownloadLinkElement.href = URL.createObjectURL(new Blob([svg], { type: "image/svg+xml;charset=utf-8" }));
        }
    }
};
