
import { blazorSvgLinksInit } from "./src/BlazorSvgLinks";
import { draw } from "./src/GraphDisplay";
import { showSvg } from "./src/QR";

const Elcome = {
    GraphDisplay: {
        draw,
    },
    QR: {
        showSvg,
    },
};

declare global {
    interface Window {
        Elcome: typeof Elcome;
    }
}

window.Elcome = Elcome;

blazorSvgLinksInit();
